@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

@font-family-regular : Quicksand, serif !important;
@font-family-bold    : Quicksand, serif !important;

body {
  font-family: @font-family-regular, serif !important;
}

/*====================================
=            BREAK POINTS            =
====================================*/
@wide-screen-down : ~'(max-width: 1235px)';
@ipad-pro-down    : ~'(max-width: 1024px)';
@desktop-down     : ~'(max-width: 988px)';
@tablet-down      : ~'(max-width: 768px)';
@phone-down       : ~'(max-width: 480px)';
@small-phone-down : ~'(max-width: 320px)';
/*====================================
=            TEXT                    =
====================================*/
@color-text         : #6F6F6F;
@size-text-h1       : 3rem;
@size-text-h1-phone : 3rem;
@size-text-h2       : 2rem;
@size-text-h3       : 1.3rem;
@size-text-p        : 1rem;